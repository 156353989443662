<template>

    <div>

        <v-layout row wrap pr-3 mr-2 mt-1>

            <v-toolbar flat color="white" pb-5>

                <v-toolbar-title style="color:#000">شراء حزمه اشتراك</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>


            </v-toolbar>
        </v-layout>


        <v-layout row wrap pr-3 mr-2 mt-1 v-if="OwnerSubscriptions==null  || OwnerSubscriptions.status.id==30 || OwnerSubscriptions.remaining_reservations_number<=0 || changePackages==true">

            <div v-if="OwnerSubscriptions.status.id==30 ">

            </div>

            <v-form ref="form" v-model="valid">
                <v-radio-group required mandatory :rules="PackagRules" v-model="editedItem.id">
                    <v-layout row wrap pa-4>






                        <v-alert v-if="!changePackages" dense type="error" style="float:right">
                            حزمه الاشتراك الحاليه منتهيه الصلاحيه
                        </v-alert>


                        <v-flex pt-2 xs12 v-for="item in  subscriptions" :key="item.id">
                            <v-card style="padding:10px;width:100%">

                                <v-radio
                                    :label=" item.package_name+' | عدد الحجوزات  '+item.reservations_count +'| السعر '+item.price_value+'دينار'"
                                    :value="item.id">


                                    <template v-slot:label>

                                        <v-layout row wrap>
                                            <v-flex xs2 md2 sm2 pr-5>
                                                <div style="text-align:right;"> <strong
                                                        style="color:green">{{item.package_name}} </strong> </div>
                                            </v-flex>

                                            <v-flex pr-3 xs5 md3 sm3>
                                                <div><span style="font-weight:bold">عدد الحجوزات
                                                    </span> <strong style="color:blue;padding-right:10px">
                                                        {{item.reservations_count}} </strong> </div>
                                            </v-flex>

                                            <v-flex xs1 md3 sm3>

                                            </v-flex>

                                            <v-flex xs4 sm3 md3>
                                                <div><strong style="color:red;padding-right:10px;">السعر </strong>
                                                    {{item.price_value}} دينار</div>

                                            </v-flex>

                                        </v-layout>



                                    </template>
                                </v-radio>
                            </v-card>
                        </v-flex>




                    </v-layout>

                    <v-layout pr-4 row wrap>
                        <v-flex xs6>
                            <v-btn color="green" style="color:#fff;width:100%" @click="next()">التالي</v-btn>
                        </v-flex>
                    </v-layout>





                </v-radio-group>
            </v-form>


            <v-dialog v-model="PayDialog" width="500px">
                <v-card style="padding:45px;">
                    <v-form ref="valid_pay" v-model="valid_pay">
                        <!-- <div v-show="bill"> -->
                        <v-divider></v-divider>
                        <!-- <v-checkbox v-model="withoutBills" label="حجز بدون فاتوره"></v-checkbox> -->
                        <v-card-title style="font-size:font-size: 18px;">
                            ويه تسديد صار تكدر تدفع فاتورتك وانت بالبيت من خلال رقم الفاتورة الي يوصلك برسالة ..
                            والتسديد من
                            خلال ادخال رقم بطاقة الدفع الكي كارد أوالماستر كارد
                        </v-card-title>
                        <v-divider></v-divider>


                        <v-layout row wrap>
                            <v-flex pa-3>

                                <v-text-field outlined dense placeholder="ادخل رقم هاتفك لانشاء فاتوره"
                                    append-icon="fas fa-phone" type="number" :rules="numRules" required
                                    v-model="editedItem.phone">
                                </v-text-field>
                            </v-flex>

                        </v-layout>
                        <v-col>

                        </v-col>
                        <!-- </div> -->

                        <!-- <div v-else></div>
 -->



                        <v-layout>
                            <v-fex xs3>
                                <v-btn @click="submit()" color="green" style="color:#fff">

                                    <span v-show="!loading"> ارسال</span>
                                    <v-progress-circular indeterminate color="white" v-show="loading">
                                    </v-progress-circular>


                                </v-btn>
                            </v-fex>
                        </v-layout>
                    </v-form>

                </v-card>
            </v-dialog>


        </v-layout>

        <v-layout v-else style="padding-right:30px">


            <div v-if="OwnerSubscriptions.status.id==28 ">
                <v-alert dense type="success">
                    
                    شكرا تم طلب حزمه  اشتراك  {{OwnerSubscriptions.subscription_package.package_name}} يتم تفعيل الحزمه عند دفع الفاتوره عند اقرب منفذ كي كارد او الاتصال بأداره المنصه  للتفعيل
                </v-alert>

                <v-btn style="color:#fff"  @click="changePackages=true;editedItem.id=OwnerSubscriptions.subscription_package.id" color="blue"> تغير الحزمه المطلوبه {{OwnerSubscriptions.subscription_package_id}}</v-btn>
            </div>

            <div v-if="OwnerSubscriptions.status.id==29 ">
                <v-alert dense type="success">
                    تم تفعيل حزمه الاشتراك
                </v-alert>



                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>


                                <th class="text-right" style=" font-weight: bold;font-size: 20px;">
                                    اسم الحزمه الحاليه
                                </th>
                                <th class="text-right" style=" font-weight: bold;font-size: 20px;">
                                    عدد الحجوزات المتبقيه
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>



                                <td style=" font-weight: bold;font-size: 19px;">
                                    {{ OwnerSubscriptions.subscription_package.package_name }}</td>
                                <td style=" font-weight: bold;font-size: 19px;">
                                    {{OwnerSubscriptions.remaining_reservations_number }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>




        </v-layout>


    </div>
</template>


<script>
    export default {
        data: () => ({
            subscriptions: [],
            validate: false,
            PayDialog: false,
            changePackages:false,
            //   loading:false,
            page: 1,

            pageCount: 0,
            numRules: [

                (v) => !!v || 'يجب ادخال رقم الهاتف',
                (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
            ],
            owner: {},
            OwnerSubscriptions: {},
            current_page: 1,
            last_page: 0,
            editedIndex: -1,
            calander_dialog: false,
            err_show: false,
            dialog: false,

            PackagRules: [
                (v) => !!v || 'يجب اختيار حزمه',

            ],
            reservations_countRules: [
                (v) => !!v || 'يجب ادخال عدد الحجوزات المسموحه',

            ],
            OwnerSubscriptions_length: 0,

            PriceRules: [
                (v) => !!v || 'يجب ادخال سعر الحزمه',

            ],

            loding_accept: false,
            loding_cancel: false,



            loading: false,
            editedItem: {
                id: '',
                package_name: '',
                reservations_count: '',
                price_value: ''
            }


        }),



        methods: {

            last(array) {
                return array[array.length - 1];
            },
            next() {
                if (this.$refs.form.validate()) {
                    this.PayDialog = true;

                }

            },

            submit() {
                if (this.$refs.valid_pay.validate()) {


                    var data = {

                        // owner_id:this.owner.id,
                        subscription_package_id: this.editedItem.id,
                        ownerPhone: this.editedItem.phone


                    };
                    var posturl;
                    if(this.changePackages)
                    {
                         posturl = "v2/OwnerSubscriptions/owner/update/"+this.OwnerSubscriptions.id;
                        

                    }
                    else
                    {
                            posturl = "v2/OwnerSubscriptions/owner/set";
                    }

                    // if (this.editedIndex > -1) {

                    //     posturl = "v2/OwnerSubscriptions/owner/update/" + this.editedItem.id;
                    // } else {
                    //     this.PayDialog = true;
                    //     posturl = "v2/OwnerSubscriptions/owner/set";


                    // }

                    this.loading = true;
                    this.$http({
                            method: 'post',
                            url: posturl,
                            data: data,
                            headers: {

                            }

                        })
                        .then(response => {


                            if (this.editedIndex > -1) {

                                // this.$swal("تم ارسال طلب الشراء", "", "success");
                                //  this.GetOwnerSubscriptions();
                                //  this.PayDialog=false;



                            }

                            else if(this.changePackages)
                            {
                                this.$swal("تم تغير حزمه الاشتراك المطلوبه", "", "success");
                                this.PayDialog = false;
                                this.changePackages=false;
                          

                            }
                            
                            else {

                                this.$swal("تم ارسال طلب الشراء", "", "success");
                                this.GetOwnerSubscriptions();
                                this.PayDialog = false;

                                // this.$swal("تم الاضافه", "", "success")


                            }
                            this.dialog = false;
                            this.loading = false;
                            this.$refs.form.reset();
                            this.getItems();

                            if (response.data.success == true) {
                                this.$refs.form.reset()
                                this.addlocation = false;


                            }
                            //



                        })
                        .catch(error => {
                            error
                        }).finally(d => {
                            d
                        });
                }

            },


            editItem(item) {
                this.dialog = true;

                this.editedItem = Object.assign({}, item)
                this.editedIndex = this.subscriptions.indexOf(item)



            },


            getItems() {
                this.loading = true;
                this.loading = true;
                this.$http({
                    method: 'get',
                    url: '/v2/SubscriptionPackages/get',
                }).then(response => {
                    this.loading = false;
                    this.subscriptions = response.data.data;
                }).catch(error => {
                    error
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },




            GetOwnerInfo() {
                this.$http({
                    method: 'get',
                    url: 'owner/v2/OwnerInfo',
                    headers: {

                    }

                }).then(response => {

                    this.owner = response.data.data

                }).catch(error => {
                    error

                }).finally(s => {
                    s

                })

            },


            GetOwnerSubscriptions() {
                this.$http({
                    method: 'get',
                    url: '/v2/OwnerSubscriptions/owner/get',
                    headers: {

                    }

                }).then(response => {

                    this.OwnerSubscriptions = response.data.data

                    console.log(this.OwnerSubscriptions);
                    //  this.OwnerSubscriptions_length=this.OwnerSubscriptions.length;


                }).catch(error => {
                    error

                }).finally(s => {
                    s

                })

            },


            close() {
                this.dialog = false;
                this.getItems();
            },
            getMoreitems() {

                if (this.current_page <= this.last_page) {
                    this.current_page = this.page;
                    this.getItems();
                }
            },
        },

        mounted() {
            this.getItems();
            this.GetOwnerSubscriptions();
            this.GetOwnerInfo();
        },


        computed: {

            selected: function () {

                return this.getMoreitems();
            }


        },


        watch: {
            selected: 'search by sub_cat_id',
        },

    }
</script>